import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import './Footer.css';

export default function Footer() {
  return (
    <Container fluid className='footer-container'>
        <Row>
            <Col>
                <div className='footer-header-container grid text-center'>
                    <h1>Ready to get started?</h1>
                    <p>Let's get in touch and work together to make your designs come to life!</p>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        <Button variant="primary" href="https://www.facebook.com/stitchitTrinidad">Visit our Facebook</Button>
                        <Button variant="success" href="tel:+18686855227">Contact Us</Button>
                    </Stack>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className='grid text-center'>
                    <p className='small'>© {new Date().getFullYear()} Stitch It TT</p>
                    <p className='small'>Powered by Caribbean Tech Solutions</p>
                </div>
            </Col>
        </Row>
    </Container>
  )
}
