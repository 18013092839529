import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SectionHeader from '../components/SectionHeader/SectionHeader'
import ClientCard from '../components/ClientCard/ClientCard'

import DigicelLogo from '../assets/client-logos/custom-caps.png';
import HadcoLogo from '../assets/client-logos/personalized-shirts.png';
import NLCBLogo from '../assets/client-logos/high-school-embroidery.jpeg';
// import RepublicBankLogo from '../assets/client-logos/republic-bank.png';
// import WASALogo from '../assets/client-logos/wasa.png';

export default function Clients() {
    const BRAND_LOGO_FILENAMES = [
        {
            current_img: DigicelLogo,
            brand_name: 'Digicel'
        },
        {
            current_img: HadcoLogo,
            brand_name: 'HADCO Limited'
        },
        {
            current_img: NLCBLogo,
            brand_name: 'National Lotteries Control Board'
        },
        // {
        //     current_img: RepublicBankLogo,
        //     brand_name: 'Republic Bank'
        // },
        // {
        //     current_img: WASALogo,
        //     brand_name: 'Water and Sewage Authority'
        // },
    ]

    return (
        <Container style={{'marginBottom': '6%'}}>
            <Row>
                <Col>
                    <SectionHeader header_text='Showcase'/>
                </Col>
            </Row>
            <Row md={3}>
                {BRAND_LOGO_FILENAMES.map((brand_obj, index) => {
                    return(
                        <Col sm={12} className='grid text-center pt-2 pb-2 mb-2'>
                            <ClientCard current_img={brand_obj.current_img} key={index} brand_name={brand_obj.brand_name}/>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    )
}
