import React from 'react';

import './SectionHeader.css';

export default function SectionHeader(props) {
  return (
    <div className='grid text-center section-header-container'>
        <h1 className='display-3 section-header'>{props.header_text}</h1>
    </div>
    
  )
}
