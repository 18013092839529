import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ServiceCard from '../components/ServiceCard/ServiceCard'
import SectionHeader from '../components/SectionHeader/SectionHeader'

import EmbroideryImage from '../assets/embroidery-img.jpeg'
import ScreenPrintingImage from '../assets/screen-printing-img.jpeg'

export default function Services() {
  return (
    <Container>
        <Row>
            <Col>
                <SectionHeader header_text='Our Services'/>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <ServiceCard 
                    header_text='Embroidery'
                    desc_text='We specialize in embroidery of Polos, Shirts, Caps – just about anything.'
                    current_img={EmbroideryImage}
                />
            </Col>
            <Col md={12}>
                <ServiceCard 
                    header_text='Screen Printing'
                    desc_text='The best quality screen printing made affordable. Give us the design and we will get you your high quality prints or alternatively, you can bring your materials.'
                    footer_text='Minimum Order of 50 pieces'
                    current_img={ScreenPrintingImage}
                />
            </Col>
        </Row>
    </Container>
  )
}
