import Landing from "./sections/Landing";
import Services from "./sections/Services";
import Clients from "./sections/Clients";

import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div>
      <Landing/>
      <Services/>
      <Clients/>
      <Footer/>
    </div>
  );
}

export default App;
