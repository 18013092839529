import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

import './ServiceCard.css'

// TODO: Make this accept props for the header, desc and img!
export default function ServiceCard(props) {
  return (
    <Container className='card-container'>
        <Row>
            <Col md={7} className='justify-content-center'>
                <h2 className='card-header'>{props.header_text}</h2>
                <p className='card-description'>{props.desc_text}</p>
                <p className='card-fine-print small'><i>{props.footer_text ? props.footer_text : "Minimum Order of 30 pieces"}</i></p>
            </Col>
            <Col md={5}>
                <div>
                    <Image src={props.current_img} className='card-img'/>
                </div>
            </Col>
        </Row>
    </Container>
  )
}
