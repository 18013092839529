import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

import Tagline from './Jumbotron-Tagline/Tagline';

import JumbotronLogo from '../../assets/blue-jumbotron-logo.png'

import "./Jumbotron.css"

function Jumbotron() {
  return (
    <Stack direction='horizontal' className='jumbotron' gap={2}>
      <div className='justify-content-center'>
        <Tagline/>
      </div>
      <div className='justify-content-center d-none d-l-block d-xl-block d-xxl-block jumbotron-img-container'>
        <Image src={JumbotronLogo} className='jumbotron-img'/>
      </div>
    </Stack>
  );
}

export default Jumbotron;