import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import "./Tagline.css"

function Tagline(){
    return(
        <Stack gap={2} className="jumbotron-tagline">
            <div className='jumbotron-tagline-header-container'>
                <h1 className='display-2'>Your Nation's Favourite Embroider!</h1>
            </div>
            <div>
                {/* <p className='lead'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis nisl vehicula, porttitor elit eu, viverra mauris.</p> */}
                <p className='lead'>Stitch it - Print it provides affordable and quick embroidery services to businesses in Trinidad & Tobago.</p>
            </div>
            <div className='jumbotron-cta-container col-sm-12'>
                <Button variant="primary" className='mb-2 jumbotron-tagline-btn' href="https://www.facebook.com/stitchitTrinidad">Visit Our Facebook</Button>
                <Button variant="success" className='mb-2 mr-5' href="tel:+18686855227">Contact Us</Button>
            </div>
        </Stack>
    )
}

export default Tagline;