import React from 'react';
// import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Image from 'react-bootstrap/Image';

import './ClientCard.css';

export default function ClientCard(props) {
    return (
        // <Container>
        //     <Row>
        //         <Col>
        //             <div className='grid text-center brand-img-container'>
        //                 <Image src={props.current_img} className='brand-img' alt={`Our Client's Logo`}/>
        //                 {/* <p className='small'>{props.brand_name}</p> */}
        //             </div>
        //         </Col>
        //     </Row>
        // </Container>
        <Card>
            <Card.Img src={props.current_img} />
        </Card>
    )
}
